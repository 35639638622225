import React from 'react'

type IIconProps = {
  color?: string
}

const InstagramIcon: React.FC<React.SVGProps<SVGSVGElement> & IIconProps> = ({
  color = 'currentColor',
  ...rest
}) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18.25 18.25" {...rest}>
      <path
        fill={color}
        d="M13.44,0H4.81A4.87,4.87,0,0,0,0,4.91v8.43a4.87,4.87,0,0,0,4.81,4.91h8.63a4.88,4.88,0,0,0,4.81-4.91V4.91A4.88,4.88,0,0,0,13.44,0Zm3,13.1a3.29,3.29,0,0,1-3.24,3.31H5.08A3.28,3.28,0,0,1,1.84,13.1V5.15A3.28,3.28,0,0,1,5.08,1.84h8.09a3.29,3.29,0,0,1,3.24,3.31Z"
      />
      <path
        fill={color}
        d="M9.12,4.57a4.56,4.56,0,0,0,0,9.11,4.56,4.56,0,0,0,0-9.11Zm0,7.21a2.66,2.66,0,1,1,2.61-2.65A2.63,2.63,0,0,1,9.12,11.78Z"
      />
      <ellipse fill={color} cx="13.83" cy="4.32" rx="1.01" ry="1.03" />
    </svg>
  )
}

export default InstagramIcon
